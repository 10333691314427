

import React, { useEffect } from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby'

const TermsOfServiceOld = () => {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.body.classList.remove('dots')
    }
  }, [])

  return (
    <Layout>
      <SEO title="Archived Terms Of Service 2019-11-14" noIndex={true} />
      <div className="terms-section container section">
      <div className="text-center">
        <h5 className="work-sans">Vision Path, Inc. | Hubble Contacts</h5>
        <h1 className="title work-sans">
          <strong>TERMS OF SERVICE</strong>
        </h1>
      </div>

      <div className="table-of-contents my-5">
        <ol className="pl-3">
          <li>
            <a href="#use-of-service">
              Access and Use of the Service
            </a>
          </li>
          <li>
            <a href="#conditions-of-use">
              Conditions of Use
            </a>
          </li>
          <li>
            <a href="#intellectual-property-rights">
              Intellectual Property Rights
            </a>
          </li>
          <li>
            <a href="#third-party-websites">
              Third Party Websites
            </a>
          </li>
          <li>
            <a href="#social-networking-services">
              Social Networking Services
            </a>
          </li>
          <li>
            <a href="#indemnity-and-release">
              Indemnity and Release
            </a>
          </li>
          <li>
            <a href="#disclaimer-of-warranties">
              Disclaimer of Warranties
            </a>
          </li>
          <li>
            <a href="#limitation-of-liability">
              Limitation of Liability
            </a>
          </li>
          <li>
            <a href="#description-of-products">
              Description of Products
            </a>
          </li>
          <li>
            <a href="#termination">
              Termination
            </a>
          </li>
          <li>
            <a href="#general">
              General
            </a>
          </li>
          <li>
            <a href="#your-privacy">
              Your Privacy
            </a>
          </li>
          <li>
            <a href="#notice-for-california-users">
              Notice for California Users
            </a>
          </li>
          <li>
            <a href="#notice-for-maine-users">
              Notice for Maine Users
            </a>
          </li>
          <li>
            <a href="#questions">
              Questions? Concerns? Suggestions?
            </a>
          </li>
        </ol>
      </div>

      <p>
        <strong>Date of Last Revision: May 24, 2019</strong>
      </p>
      <p className="legal-text">
        Welcome to Vision Path, Inc. (Hubble Contacts)! We really hate to put you through this, but these Terms
        of Service contain all sorts of important information you should have when visiting hubblecontacts.com
        and buying our fabulous lenses.
      </p>
      <p className="legal-text">
        <i>
          <strong>
            THESE TERMS OF SERVICE CONTAIN AN ARBITRATION AGREEMENT, WHICH WILL, WITH LIMITED
            EXCEPTIONS, REQUIRE YOU TO SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND FINAL
            ARBITRATION. UNDER THE ARBITRATION AGREEMENT, (1) YOU WILL ONLY BE PERMITTED TO PURSUE
            CLAIMS AGAINST VISION PATH, INC. ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER
            IN ANY CLASS OR REPRESENTATIVE ACTION OR PROCEEDING, AND (2) YOU WILL ONLY BE PERMITTED TO
            SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ON AN INDIVIDUAL
            BASIS.
          </strong>
        </i>
      </p>
      <p className="legal-text">
        Vision Path, Inc. (“Company,” “we,” “us,” “our”) provides its services (described below) to you through its website located at www.hubblecontacts.com (the “Site”) and through its related services (collectively, such services, including any new features, and the Site, the “Service(s)”), subject to the following Terms of Service (as amended from time to time, the “Terms of Service”). Please note that these Terms of Service do not apply to other websites operated by the Company, including <a href="https://www.contactscart.com" rel="noopener noreferrer" target="_blank">www.contactscart.com</a>. We reserve the right, at our sole discretion, to change or modify portions of these Terms of Service by posting a notice on the Site or by sending you notice through the Services, via e-mail or by another appropriate means of electronic communication. Any such changes will become effective no earlier than fourteen (14) days after they are posted or notice is sent, as applicable, except that changes addressing new functions of the Service or changes made for legal reasons will be effective immediately. Your continued use of the Service after the date any such changes become effective constitutes your acceptance of the new Terms of Service.
      </p>
      <p className="legal-text">
        In addition, when using certain services, you will be subject to any additional terms applicable to such services that may be posted on the Service from time to time, including, without limitation, the Privacy Policy located <Link to="/privacy-policy">here</Link>. All such terms are hereby incorporated by reference into these Terms of Service.
      </p>

      <p id="use-of-service">
        <strong>
          <u>Access and Use of the Service</u>
        </strong>
      </p>
      <p className="legal-text">
        <span className="mx-5">1.</span> <strong>Services Description:</strong> The Service is designed to sell our Hubble contact lenses directly to you by means of a subscription plan. More information about the Hubble subscription offering is in Section 6, below. We aim to make the process efficient and easy for you, for example, by providing the names of medical professionals in your area with whom you can make an appointment for a fitting and to obtain your prescription should you not currently have a valid Hubble contact lens prescription. Please note, however, that Company is not a healthcare provider and such medical professionals are not our employees or contractors. To be clear, these medical professionals do not receive any form of compensation from Company and they will only prescribe Hubble contact lenses for you if they believe Hubble contact lenses are the most suitable contact lenses for you. In view of the totally independent relationship between Company and these medical professionals, you acknowledge that Company is not liable for any loss or claim that you may have against any such medical professional. Also, please keep in mind that, just like any visit to your doctor, you are responsible for the cost of your appointment. However, many of these medical professionals accept vision insurance (assuming you have appropriate coverage remaining). Please contact <a href="mailto:help@hubblecontacts.com"> help@hubblecontacts.com</a> for more information about the medical professionals in your area.
      </p>
      <p className="legal-text">
        <strong>Notice:</strong> <u>WARNING:</u> <i>IF YOU ARE HAVING ANY UNEXPLAINED EYE DISCOMFORT, WATERING, VISION CHANGE, OR REDNESS, REMOVE YOUR LENSES IMMEDIATELY AND CONSULT YOUR EYE CARE PRACTITIONER BEFORE WEARING YOUR LENSES AGAIN.</i>
      </p>

      <p className="legal-text">
        <span className="mx-5">2.</span> <span className="mx-5">a)</span> <strong>Your Obligations Regarding Your Prescription:</strong> You must have a valid Hubble prescription from a qualified medical professional to use the Service. <strong>It is your responsibility to ensure that you enter requested information for use of the Service in full conformity with what your medical professional has prescribed.</strong> But please don’t worry because, before we ship any product to you, we’ll reach out to your medical professional to verify that we have the correct prescription information. Additionally, as you will be alerted before you click "checkout", we'll reach out to your doctor when your prescription is expiring to ensure it's still valid and accurate and that shipments to you may continue uninterrupted. But we'll check in with you before contacting your doctor to re-verify your prescription.
      </p>
      <p className="legal-text">
        Additionally, it is your responsibility to ensure you are using and providing a valid email address. If the email
        address you provide is incorrect, the services you request may be interrupted and we will not be
        responsible for that interruption. Your prescription information, email address and certain other
        information about you are governed by our Privacy Policy located <Link to="/privacy-policy">here</Link>. If you are under 13 years of age,
        you are not authorized to use the Service. In addition, if you are under 18 years old, you may use the Service
        only with the approval of your parent or guardian.
      </p>
      <p className="legal-text">
        <span className="mx-5"> </span> <span className="mx-5">b)</span> <strong>Verification Process Notice for Medical Professionals:</strong> We use voicemail and phone for outbound verification communications. Our preferred mode of communication whenever possible is voicemail to not tie up doctors' phone lines when their offices are open, and patients are trying to call them. For verification responses, we use a specially designed fax system so there is never a busy signal when offices are trying to send a fax.
      </p>

      <p className="legal-text">
        <span className="mx-5">3.</span> <strong>Member Account, Password and Security: </strong> You are responsible for maintaining the confidentiality of your password and account, if any, and are fully responsible for any and all activities that occur under your password or account. You agree to (a) immediately notify Company of any unauthorized use of your password or account or any other breach of security, and (b) ensure that you exit from your account at the end of each session when accessing the Service. Company will not be liable for any loss or damage arising from your failure to comply with this Section.
      </p>

      <p className="legal-text">
        <span className="mx-5">4.</span> <strong>Modifications to Service: </strong> Company reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that Company will not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.
      </p>

      <p className="legal-text">
        <span className="mx-5">5.</span> <strong>Mobile Services: </strong> To the extent you accessthe Site through a mobile device, your wireless service carrier’s standard charges, data rates and other fees may apply.
      </p>


      <p id="conditions-of-use">
        <strong>
          <u>Conditions of Use</u>
        </strong>
      </p>
      <p className="legal-text">
        <span className="mx-5">1.</span> <strong>No Medical or Other Professional Advice Rendered:</strong> You acknowledge that any information offered on the Service or otherwise to you by Company is intended for informational purposes only and not as a substitute for the advice of your medical professional. It is essential that you not make any medical decisions without first consulting with your medical professional. Company’s communications with you, whether on the Service or through emails or other direct forms of communication, do not create a medical professional-patient relationship in any respect nor do they represent an expansion of Company’s Privacy Policy. You agree that you must evaluate, and bear all risks associated with, the use of any content, including any reliance on the accuracy, completeness, or usefulness of such content.
      </p>

      <p className="legal-text">
        <span className="mx-5">2.</span> <strong>User Conduct:</strong>
      </p>
      <p className="legal-text">
        You agree to not use the Service to:
      </p>
      <p className="legal-text">
        <span className="mx-5">a)</span> interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service;
      </p>
      <p className="legal-text">
        <span className="mx-5">b)</span> violate any applicable local, state, national or international law, or any regulations having the force of law;
      </p>
      <p className="legal-text">
        <span className="mx-5">c)</span> impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;
      </p>
      <p className="legal-text">
        <span className="mx-5">d)</span> solicit personal information from anyone under the age of 18;
      </p>
      <p className="legal-text">
        <span className="mx-5">e)</span> harvest or collect email addresses or other contact information of other users from the Service by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications;
      </p>
      <p className="legal-text">
        <span className="mx-5">f)</span> advertise or offer to sell or buy any goods or services for any business purpose that is not specifically authorized;
      </p>
      <p className="legal-text">
        <span className="mx-5">g)</span> further or promote any criminal activity or enterprise or provide instructional information about illegal activities; or
      </p>
      <p className="legal-text">
        <span className="mx-5">h)</span> obtain or attempt to access or otherwise obtain any materials or information through any means not intentionally made available or provided for through the Service.
      </p>

      <p>
        <strong>
          The reselling of lenses you purchase on the Service is strictly prohibited unless you are a medical professional licensed to do so.
        </strong>
      </p>

      <p className="legal-text">
        <span className="mx-5">3.</span> <strong>Fees:</strong>  To the extent the Service or any portion thereof is made available for any fee, you will be required to select a payment plan and provide Company information regarding your credit card. You represent and warrant to Company that such information is true and that you are authorized to use the credit card concerned. You will promptly update your account information with any changes (for example, a change in your billing address or credit card expiration date) that may occur. You agree to pay Company the amount that is specified in the payment plan in accordance with the terms of such plan and these Terms of Service. You hereby authorize Company to bill your payment instrument in advance on a periodic basis, in accordance with the subscription plan you select, until you terminate your account, and you further agree to pay any charges so incurred including but not limited to any charges due at the time of termination. If you dispute any charges you must advise Company within sixty (60) days after the date that Company charges you. We reserve the right to change Company’s prices. If Company does change prices, Company will provide notice of the change on the Service or in email to you, at Company’s option, at least 20 days before the change is to take effect. Your continued use of the Service after the price change becomes effective constitutes your agreement to pay the changed amount. (Company may lower the price  of our Hubble contact lenses or other products and we will also notify you should that occur.) You shall be responsible for all taxes associated with the Services other than U.S. taxes based on Company’s net income.
      </p>

      <p className="legal-text">
        <span className="mx-5">4.</span> <strong>Special Notice for International Use; Export Controls: </strong> Software (defined below) available in connection with the Service and the transmission of applicable data, if any, is subject to United States export controls. No Software may be downloaded from the Service or otherwise exported or re-exported in violation of U.S. export laws. Downloading or using the Software is at your sole risk. Recognizing the global nature of the Internet, you agree to comply with all local rules and laws regarding your use of the Service, including as it concerns online conduct and acceptable content.
      </p>

      <p className="legal-text">
        <span className="mx-5">5.</span> <strong>Commercial Use:</strong> Unless otherwise expressly authorized herein or in the Service, you agree not to display, distribute, license, perform, publish, reproduce, duplicate, copy, create derivative works from, modify, sell, resell, exploit, transfer or upload for any commercial purposes, any portion of the Service, use of the Service, or access to the Service. The Service is for your personal use only.
      </p>

      <p className="legal-text">
        <span className="mx-5">6.</span> <strong>Subscription and Subscription Cancellation:</strong> If you are a new customer, your first shipment will consist of 30 discounted Hubble lenses after we verify your contact lens prescription. After that first shipment, beginning 16 days after you placed an order for those first 30 Hubble lenses, you will be charged for the lenses plus shipping and handling every 28 days unless you cancel your subscription. If you are ever overstocked with Hubble lenses or need to delay a shipment for any reason, you can do that in your account page. If you wish to cancel your subscription you may do so at any time by calling us at 1- 844-334-1640 between 9AM and 5PM EST, Monday through Friday, and 1PM and 5PM on Saturday excluding federal holidays. <strong>Notice for California Users:</strong> California customers may also cancel their subscriptions via email at help@hubblecontacts.com.
      </p>

      <p className="legal-text">
        <span className="mx-5">7.</span> <strong>Return Policy:</strong> Return Policy: It’s important to us that you are 100% happy with the Service and your Hubble contact lenses. While Hubble does not accept returns, please don’t let that stop you from getting in touch about any issue with your order! (Particularly if we made a mistake, which we work hard to avoid, but can happen every now and again.) In the unlikely event that any of the Hubble contact lenses you receive are different than what you ordered, are defective, or are in open blister packs, we will replace those lenses at no cost to you. Please just email Company at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a> or call <a href="tel:1-844-334-1640">1-844-334-1640</a> to speak with us about the problem so that we can resolve it with you as efficiently and painlessly as possible and set things right. (Kentucky residents may also call us at <a href="tel: 859-577-7538">(859) 577-7538</a>.) If you started your Hubble subscription before November 1, 2018, Hubble’s previous return policy, which you can find here, may still apply to you.
      </p>
      <p className="legal-text">
        If you are unhappy with the Service or your Hubble contact lenses for any other reason, please reach out to us at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a> or 1-844-334-1640 and give us a chance to make you HAPPY, HAPPY, HAPPY!
      </p>

      <p id="intellectual-property-rights">
        <strong>
          <u>Intellectual Property Rights</u>
        </strong>
      </p>
      <p className="legal-text">
        <span className="mx-5">1.</span> <strong>Service Content, Software and Trademarks:</strong> You acknowledge and agree that the Service may contain content or features (“Service Content”) that are protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. Except as expressly authorized by Company, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the Service or the Service Content, in whole or in part. In connection with your use of the Service you will not engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. If you are blocked by Company from accessing the Service (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy  5 IP address). Any use of the Service or the Service Content other than as specifically authorized herein is strictly prohibited. The technology and software underlying the Service or distributed in connection therewith are the property of Company, our affiliates and our partners (the “Software”). You agree not to copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in the Software. Any rights not expressly granted herein are reserved by Company.
      </p>
      <p className="legal-text">
        The Company name and logos are trademarks and service marks of Company (collectively the “Company Trademarks”). Other company, product, and service names and logos used and displayed via the Service may be trademarks or service marks of their respective owners who may or may not endorse or be affiliated with or connected to Company. Nothing in these Terms of Service or the Service should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of Company Trademarks displayed on the Service, without our prior written permission in each instance. All goodwill generated from the use of Company Trademarks will inure to our exclusive benefit.
      </p>

      <p id="third-party-websites">
        <strong>
          <u>Third Party Websites</u>
        </strong>
      </p>
      <p className="legal-text">
        The Service may provide, or third parties may provide, links or other access to other sites and resources on
        the Internet. Company has no control over such sites and resources and Company is not responsible for
        and does not endorse such sites and resources, including but not limited to any optometrists or other
        medical professionals. You further acknowledge and agree that Company will not be responsible or liable,
        directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use
        of or reliance on any content, events, goods or services available on or through any such site or resource.
        Any dealings you have with third parties, including optometrists or other medical professionals, found
        while using the Service are between you and the third party, and you agree that Company is not liable for
        any loss or claim that you may have against any such third party.
      </p>

      <p id="social-networking-services">
        <strong>
          <u>Social Networking Services</u>
        </strong>
      </p>
      <p className="legal-text">
        While Company does not currently allow for log in to the Service via online third party services, such as
        social media and social networking services like Facebook or Twitter (“Social Networking Services”), the
        following terms and conditions will apply in the event Company allows such log in in the future. By logging
        in or directly integrating Social Networking Services into the Service, we make your online experiences
        richer and more personalized. To take advantage of this feature and capabilities, we may ask you to
        authenticate, register for or log into Social Networking Services on the websites of their respective
        providers. As part of such integration, the Social Networking Services will provide us with access to certain
        information that you have provided to such Social Networking Services, and we will use, store and disclose
        such information in accordance with our Privacy Policy. For more information about the implications of
        activating Social Networking Services and Company’s use, storage and disclosure of information related to
        you and your use of such services within Company (including your friend lists and the like), see our Privacy
        Policy <Link to="/privacy-policy">here</Link>. However, remember that the manner in which Social Networking Services use, store and
        disclose your information is governed solely by the policies of such third parties, and Company shall have
        no liability or responsibility for the privacy practices or other actions of any third-party site or service that
        may be enabled within the Service.
      </p>
      <p className="legal-text">
        In addition, Company is not responsible for the accuracy, availability or reliability of any information,
        content, goods, data, opinions, advice or statements made available in connection with Social Networking
        Services. As such, Company is not liable for any damage or loss caused or alleged to be caused by or in
        connection with use of or reliance on any such Social Networking Services. Company enables these features
        merely as a convenience and the integration or inclusion of such features does not imply an endorsement
        or recommendation.
      </p>

      <p id="indemnity-and-release">
        <strong>
          <u>Indemnity and Release</u>
        </strong>
      </p>
      <p className="legal-text">
        You agree to release, indemnify and hold Company and its affiliates and their officers, employees, directors
        and agents harmless from any from any and all losses, damages, expenses, including reasonable attorneys’
        fees, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of
        the Service, any User Content, your connection to the Service, your violation of these Terms of Service or
        your violation of any rights of another. If you are a California resident, you waive California Civil Code
        Section 1542, which says: “A general release does not extend to claims which the creditor does not know or
        suspect to existin hisfavor at the time of executing the release, which if known by him must have materially
        affected his settlement with the debtor.” If you are a resident of another jurisdiction, you waive any
        comparable statute or doctrine.
      </p>

      <p id="disclaimer-of-warranties">
        <strong>
          <u>Disclaimer of Warranties</u>
        </strong>
      </p>
      <p className="legal-text">
        YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS
        AVAILABLE” BASIS. COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
        IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
        COMPANY MAKES NO WARRANTY THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS, (II) THE
        SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE
        OBTAINED FROM THE USE OF THE SERVICE WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY
        PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH
        THE SERVICE WILL MEET YOUR EXPECTATIONS.
      </p>

      <p id="limitation-of-liability">
        <strong>
          <u>Limitation of Liability</u>
        </strong>
      </p>
      <p className="legal-text">
        YOU EXPRESSLY UNDERSTAND AND AGREE THAT COMPANY WILL NOT BE LIABLE FOR ANY INDIRECT,
        INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS
        INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE
        LOSSES (EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED
        ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE OR
        THE INABILITY TOUSE THE SERVICE; (II) THE COSTOF PROCUREMENTOF SUBSTITUTEGOODS AND SERVICES
        RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR
        MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III)
        UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR
        CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE.
        IN NO EVENT WILL COMPANY’S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF
        ACTION EXCEED THE AMOUNT YOU HAVE PAID COMPANY IN THE LAST SIX (6) MONTHS, OR, IF GREATER,
        ONE HUNDRED DOLLARS ($100).
      </p>
      <p className="legal-text">
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
        EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
        ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY
        PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
        DISCONTINUE USE OF THE SERVICE.
      </p>

      <p id="description-of-products">
        <strong>
          <u>Description of Products:</u>
        </strong>
      </p>
      <p className="legal-text">
        Company makes every effort to provide accurate descriptions of the products sold through the Service.
        However, we do not make any warranties or representations as to whether those descriptions or any other
        content on the Service are accurate, current or free from error. If you believe that a product you purchased
        on the Service is defective, yoursole remedy is set forth in the Returns section above.
      </p>

      <p>
        <strong>
          <u>Dispute Resolution by Binding Arbitration:</u> PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS
        </strong>
      </p>
      <p className="legal-text">
        <p className="ml-5">
          <strong>1. Agreement to Arbitrate</strong>
        </p>
      </p>
      <p className="legal-text">
        This Dispute Resolution by Binding Arbitration section is referred to in these Terms of Service as the
        “Arbitration Agreement.” You agree that any and all disputes or claims that have arisen or may arise
        between you and Company, whether arising out of or relating to these Terms of Service (including any
        alleged breach thereof), the Services, any advertising, or any aspect of the relationship or transactions
        between us, shall be resolved exclusively through final and binding arbitration, rather than a court, in
        accordance with the terms of this Arbitration Agreement, except that you may assert individual claims in
        small claims court, if your claims qualify. Further, this Arbitration Agreement does not preclude youfrom
        bringing issuesto the attention of federal,state, orlocal agencies, and such agencies can, if the law allows,
        seek relief against us on your behalf. You agree that, by entering into these Terms of Service, you and
        Company are each waiving the right to a trial by jury or to participate in a class action. Your rights will be
        determined by a neutral arbitrator, not a judge or jury. The Federal Arbitration Act governs the
        interpretation and enforcement of this Arbitration Agreement.
      </p>

      <p className="legal-text">
        <p className="ml-5">
          <strong>2. Prohibition of Class and Representative Actions and Non-Individualized Relief</strong>
        </p>
      </p>
      <p className="legal-text">
        <strong>
          YOU AND COMPANY AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
          INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
          REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND COMPANY AGREE OTHERWISE, THE
          ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON’S OR PARTY’S CLAIMS AND
          MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS
          PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
          DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE
          EXTENTNECESSARY TO PROVIDE RELIEFNECESSITATEDBY THAT PARTY’S INDIVIDUAL CLAIM(S).
        </strong>
      </p>

      <p className="legal-text">
        <p className="ml-5">
          <strong>3. Pre-Arbitration Dispute Resolution</strong>
        </p>
      </p>
      <p className="legal-text">
        Company is always interested in resolving disputes amicably and efficiently, and most customer concerns can be resolved quickly and to the customer’s satisfaction by emailing customer support at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a>. If such efforts prove unsuccessful, a party who intends to seek arbitration must first send to the other, by certified mail, a written Notice of Dispute (“Notice”). The Notice to Company should be sent to Vision Path, Inc., PO Box 20589, New York, NY 10023, Attention: President (“Notice Address”). The Notice must (i) describe the nature and basis of the claim or dispute and (ii) set forth the specific relief sought. If Company and you do not resolve the claim within sixty (60) calendar days after the Notice is received, you or Company may commence an arbitration proceeding. During the arbitration, the amount of any settlement offer made by Company or you shall not be disclosed to the arbitrator until after the arbitrator determines the amount, if any, to which you or Company is entitled.
      </p>

      <p className="legal-text">
        <p className="ml-5">
          <strong>4. Arbitration Procedures</strong>
        </p>
      </p>
      <p className="legal-text">
        Arbitration will be conducted by a neutral arbitrator in accordance with the rules and regulations of the American Arbitration Association (“AAA”), including the AAA’s Supplementary Procedures for ConsumerRelated Disputes (collectively, the “AAA Rules”), as modified by this Arbitration Agreement. For information on the AAA, please visit its website, http://www.adr.org. Information about the AAA Rules and fees for consumer disputes can be found at the AAA’s consumer arbitration page, http://www.adr.org/consumer_arbitration. If there is any inconsistency between any term of the AAA Rules and any term of this Arbitration Agreement, the applicable terms of this Arbitration Agreement will control unless the arbitrator determines that the application of the inconsistent Arbitration Agreement terms would not result in a fundamentally fair arbitration. The arbitrator must also follow the provisions of these Terms of Service as a court would. All issues are for the arbitrator to decide, including, but not limited to, issues relating to the scope, enforceability, and arbitrability of this Arbitration Agreement. Although arbitration proceedings are usually simpler and more streamlined than trials and other judicial proceedings, the arbitrator can award the same damages and relief on an individual basis that a court can award to an individual under the Terms of Service and applicable law. Decisions by the arbitrator are enforceable in court and may be overturned by a court only for very limited reasons.
      </p>
      <p className="legal-text">
        Any arbitration hearings will take place in New York, New York. If your claim is for $10,000 or less, Company
        agrees that you may choose whether the arbitration will be conducted solely on the basis of documents
        submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as established by the
        AAA Rules. If your claim exceeds $10,000, the right to a hearing will be determined by the AAA Rules.
        Regardless of the manner in which the arbitration is conducted, the arbitrator shall issue a reasonedwritten
        decision sufficientto explain the essentialfindings and conclusions onwhich the award is based. Payment of
        all filing, administration, attorneys’ and arbitrator fees will be governed by the AAA Rules.
      </p>

      <p className="legal-text">
        <p className="ml-5">
          <strong>5. Confidentiality</strong>
        </p>
      </p>
      <p className="legal-text">
        All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be strictly
        confidential for the benefit of all parties.
      </p>

      <p className="legal-text">
        <p className="ml-5">
          <strong>6. Severability</strong>
        </p>
      </p>
      <p className="legal-text">
        If a court or the arbitrator decides that any term or provision of this Arbitration Agreement (other than the
        Section titled “Prohibition of Class and Representative Actions and Non-Individualized Relief” above) is
        invalid or unenforceable, the parties agree to replace such term or provision with a term or provision that
        is valid and enforceable and that comes closest to expressing the intention of the invalid or unenforceable
        term or provision, and this Arbitration Agreement shall be enforceable as so modified. If a court or the
        arbitrator decides that any of the provisions of the Section above titled “Prohibition of Class and
        Representative Actions andNon-Individualized Relief” are invalid or unenforceable,then the entirety of this
        Arbitration Agreement shall be null and void. The remainder of the Terms of Service will continue to apply.
      </p>

      <p className="legal-text">
        <p className="ml-5">
          <strong>7. Future Changes to Arbitration Agreement</strong>
        </p>
      </p>
      <p className="legal-text">
        Notwithstanding any provision in these Terms of Service to the contrary, Company agrees that if it makes
        any future change to this Arbitration Agreement (other than a change to the Notice Address) while you are
        a user of the Services, you may reject any such change by sending Company written notice within thirty
        (30) calendar days of the change to the Notice Address provided above. By rejecting any future change,
        you are agreeing that you will arbitrate any dispute between us in accordance with the language of this
        Arbitration Agreement as of the date you first accepted these Terms of Service (or accepted any
        subsequent changes to these Terms of Service).
      </p>

      <p id="termination">
        <strong>
          <u>Termination</u>
        </strong>
      </p>
      <p className="legal-text">
        You agree that Company, in its sole discretion, may suspend or terminate your account (or any part thereof)
        or use of the Service and remove and discard any content within the Service, for any reason, including,
        without limitation, for lack of use or if Company believes that you have violated or acted inconsistently
        with the letter or spirit of these Terms of Service. Any suspected fraudulent, abusive or illegal activity that
        may be grounds for termination of your use of the Service, may be referred to appropriate law enforcement
        authorities. Company may also in its sole discretion and at any time discontinue providing the Service, or
        any part thereof, with or without notice. You agree that any termination of your access to the Service
        under any provision of these Terms of Service may be affected without prior notice, and acknowledge and
        agree that Company may immediately deactivate or delete your account and all related information and
        files in your account and/or bar any further access to such files or the Service. Further, you agree that
        Company will not be liable to you or any third party for any termination of your access to the Service.
      </p>

      <p id="general">
        <strong>
          <u>General</u>
        </strong>
      </p>
      <p className="legal-text">
        These Terms of Service constitute the entire agreement between you and Company and govern your use
        of the Service, superseding any prior agreements between you and Company with respect to the Service.
        You also may be subject to additional terms and conditions that may apply when you use affiliate or third
        party services, third party content or third party software. These Terms of Service will be governed by the
        laws of the State of New York without regard to its conflict of law provisions. With respect to any disputes
        or claims not subject to arbitration, as set forth above, you and Company agree to submit to the personal
        and exclusive jurisdiction of the state and federal courts located within New York, New York. The failure of
        Company to exercise or enforce any right or provision of these Terms of Service will not constitute a waiver
        of such right or provision. If any provision of these Terms of Service is found by a court of competent
        jurisdiction to be invalid, the parties nevertheless agree that the courtshould endeavorto give effect to the
        parties’ intentions as reflected in the provision, and the other provisions of these Terms of Service remain
        in full force and effect. You agree that regardless of any statute or law to the contrary, any claim or cause of
        action arising out of or related to use of the Service or these Terms of Service must be filed within one (1)
        year after such claim or cause of action arose or be forever barred. A printed version ofthis agreement and
        of any notice given in electronic formwill be admissible in judicial or administrative proceedings based upon
        or relating to this agreement to the same extent and subject to the same conditions as other business
        documents and records originally generated and maintained in printed form. You may not assign these
        Terms of Service without the prior written consent of Company, but Company may assign or transfer these
        Terms of Service, in whole or in part, without restriction. The section titles in these Terms of Service are for
        convenience only and have no legal or contractual effect. Notices to you may be made via either email or
        regular mail. The Service may also provide notices to you of changes to these Terms of Service or other
        matters by displaying notices or links to notices generally on theService.
      </p>

      <p id="your-privacy">
        <strong>
          <u>Your Privacy</u>
        </strong>
      </p>
      <p className="legal-text">
        At Company, we respect the privacy of our users. For details see our Privacy Policy. By using the Service,
        you consent to our collection and use of personal data as outlined therein.
      </p>

      <p id="notice-for-california-users">
        <strong>
          <u>Notice for California Users</u>
        </strong>
      </p>
      <p className="legal-text">
        Under California Civil Code Section 1789.3, users of the Service from California are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210. You may contact us at 10 Vision Path, Inc., PO Box 20589, New York, NY 10023, Attention: President or by telephone at 1-844-334-1640
      </p>

      <p id="notice-for-maine-users" className="legal-text">
        <strong>Notice for Maine Users</strong>
        <br />
        Under Title 32, Chapter 117 Maine Pharmacy Act and the associated implementing Rules, users of the
        Service from Maine are entitled to the following specific consumer rights notice: Complaints against the
        mail order contact lens supplier may be filed with the Complaint Coordinator, Office of Licensing and
        Registration, 35 State House Station, Augusta, ME 04333, tel. (207) 624-8660, or on the worldwide web at
        www.MaineProfessionalReg.org.
      </p>

      <p id="questions">
        <strong>
          <u>Questions? Concerns? Suggestions?</u>
        </strong>
      </p>
      <p className="legal-text">
        Whew…you made it through and we hope you found some useful information in these Terms of Service. Please contact us at <a href="mailto:help@hubblecontacts.com">help@hubblecontacts.com</a> or Vision Path, Inc., PO Box 20589, New York, NY 10023,
        <br />
        Attention: President to report any violations of these Terms of Service or to pose any questions regarding
        these Terms of Service or the Service.
      </p>

    </div>
    </Layout>
  )
}

export default TermsOfServiceOld



